/* eslint-disable @typescript-eslint/naming-convention */
import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { UserAccountQuery } from '../store/userAccount/user-account.query';

@Injectable({
  providedIn: 'root'
})
export class ViewSwitcherService {
  private _router = inject(Router);
  private _userAccountQuery = inject(UserAccountQuery);

  public appViews: PortalAppViewConfig[] = [
    { title: 'buyer', icon: 'ti ti-currency-dollar' },
    { title: 'supplier', icon: 'ti ti-truck-delivery' }
  ];

  public userRoles = this._userAccountQuery.session?.userRoles;
  public currentAppView = signal<PortalAppView>('buyer');

  public constructor() {
    this.setAppView(
      this.userRoles?.includes('Supplier') && this.userRoles.length === 1
        ? 'supplier'
        : 'buyer'
    );
  }

  public setAppView(
    view: PortalAppView,
    opts?: { resetToHome?: boolean }
  ): void {
    this.currentAppView.set(view);

    opts?.resetToHome && this._router.navigate(['home']);
  }
}

export type PortalAppView = 'buyer' | 'supplier';
export type PortalAppViewConfig = {
  title: PortalAppView;
  icon: string;
};
